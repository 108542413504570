<template>
  <div id="myModal" class="modal" @click="closeModal">
    <span class="close">
      <svg
        width="20"
        height="20"
        viewBox="0 0 10 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.5 0.5L0.5 9.5M0.5 0.5L9.5 9.5"
          stroke="white"
          stroke-linecap="round"
        />
      </svg>
    </span>
    <img @click.stop="" class="modal-content" id="img01" />
  </div>
  <div class="containerr">
    <div
      v-for="(slide, i) in allMedia"
      :key="i"
      class="mySlides position-relative"
    >
      <div class="numbertext">{{ slideIndex }} / {{ allMedia.length }}</div>
      <template v-if="slide.image">
        <img :src="baseUrl + slide.image" style="width: 100%" />
        <div @click="openImg(slide.image)">
          <fa class="icon" :icon="['fas', 'magnifying-glass-plus']" />
        </div>
      </template>
      <template v-else-if="slide.video">
        <video controls :id="'video' + ++i" style="width: 100%; height: 100%">
          <source :src="baseUrl + slide.video" type="video/mp4" />
        </video>
      </template>
      <img v-else src="../../assets/no-image.png" alt="" class="border" />
    </div>
    <button class="prev" @click="getMinusOne" :disabled="slideIndex == 1">
      <img src="../../assets/left.svg" alt="" />
    </button>
    <button
      class="next"
      @click="getPlusOne"
      :disabled="slideIndex == allMedia.length"
    >
      <img src="../../assets/right.svg" alt="" />
    </button>

    <div class="roww" id="roww">
      <div v-for="(currentImg, i) in allMedia" :key="i" class="column">
        <template v-if="currentImg.image">
          <img
            class="demo cursor"
            :src="baseUrl + currentImg.image"
            @click="currentSlide(i + 1)"
            alt=""
          />
        </template>
        <template v-else-if="currentImg.video">
          <img
            src="../../assets/video-back.png"
            @click="currentSlide(i + 1)"
            class="demo cursor border"
            alt=""
          />
        </template>
        <img
          v-else
          src="https://media.istockphoto.com/vectors/default-image-icon-vector-missing-picture-page-for-website-design-or-vector-id1357365823?k=20&m=1357365823&s=612x612&w=0&h=ZH0MQpeUoSHM3G2AWzc8KkGYRg4uP_kuu0Za8GFxdFc="
          @click="currentSlide(i + 1)"
          class="demo cursor border"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["allMedia"],
  data() {
    return {
      slideIndex: 1,
      baseUrl: process.env.VUE_APP_IMAGE_URL,
    };
  },
  methods: {
    closeModal() {
      let modal = document.getElementById("myModal");
      modal.style.display = "none";
    },
    openImg(val) {
      let modal = document.getElementById("myModal");

      // Get the image and insert it inside the modal - use its "alt" text as a caption

      let modalImg = document.getElementById("img01");
      // let that = this;

      modal.style.display = "block";
      modalImg.src = this.baseUrl + val;
      // modalImg.src = val;

      // Get the <span> element that closes the modal
      let span = document.getElementsByClassName("close")[0];

      // When the user clicks on <span> (x), close the modal
      span.onclick = function () {
        modal.style.display = "none";
      };
    },
    getMinusOne() {
      this.plusSlides(-1);
      let getWidth = document.getElementById("roww");
      getWidth.scrollLeft -= 50;
    },
    getPlusOne() {
      this.plusSlides(1);
      let getWidth = document.getElementById("roww");
      getWidth.scrollBy(50, 0);
    },
    plusSlides(n) {
      this.showSlides((this.slideIndex += n));
    },
    currentSlide(n) {
      let getWidth = document.getElementById("roww");
      if (n > this.slideIndex) {
        getWidth.scrollBy(100, 0);
      } else {
        getWidth.scrollLeft -= 100;
      }
      this.showSlides((this.slideIndex = n));
    },
    showSlides(n) {
      let i;
      let slides = document.getElementsByClassName("mySlides");
      let dots = document.getElementsByClassName("demo");
      // let captionText = document.getElementById("caption");
      if (n > slides.length) {
        this.slideIndex = 1;
      }
      if (n < 1) {
        this.slideIndex = slides.length;
      }
      for (i = 0; i < slides.length; i++) {
        slides[i].style.display = "none";
      }
      for (i = 0; i < dots.length; i++) {
        dots[i].className = dots[i].className.replace(" active", "");
      }
      slides[this.slideIndex - 1].style.display = "block";
      dots[this.slideIndex - 1].className += " active";
      // captionText.innerHTML = dots[this.slideIndex - 1].alt;
    },
  },
  mounted() {
    this.showSlides(this.slideIndex);
  },
  watch: {
    slideIndex(val, old) {
      const a = document.getElementById("video" + old);
      a?.pause();
    },
  },
};
</script>

<style scoped>
::-webkit-scrollbar {
  display: none;
}
.icon {
  position: absolute;
  right: 0;
  bottom: 0;
  color: #f2f2f2;
  /* font-size: 12px; */
  border-radius: 2px 0 0 0;
  padding: 12px 1rem;
  background: rgba(0, 0, 0, 0.4);
  cursor: pointer;
}
img {
  vertical-align: middle;
}

/* Position the image container (needed to position the left and right arrows) */
.containerr {
  position: relative;
}

/* Hide the images by default */
.mySlides {
  display: none;
  height: 240px;
  margin-bottom: 10px;
}
.mySlides img {
  height: 100%;
  object-fit: cover;
  border-radius: 2px;
}
/* Add a pointer when hovering over the thumbnail images */
.cursor {
  cursor: pointer;
}

/* Next & previous buttons */
.prev,
.next {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  width: 20px;
  height: 100px;
  color: white;
  font-weight: bold;
  font-size: 20px;
  border-radius: 0px;
  user-select: none;
  -webkit-user-select: none;
  background: #dadada;
  transition: all 0.2s ease;
}

/* Position the "next button" to the right */
.next {
  right: 0;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover,
.next:hover {
  background-color: rgb(162, 161, 161);
}
button:disabled:hover {
  background: #dadada;
}
/* Number text (1/3 etc) */
.numbertext {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 2px 0 2px 0;
}
.roww {
  margin-top: 10px;
  display: flex;
  gap: 2px;
  width: 90%;
  overflow-x: auto;
  margin: 0 auto;
}
.column img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 2px;
}
.roww:after {
  content: "";
  display: table;
  clear: both;
}

/* Six columns side by side */

/* Add a transparency effect for thumnbail images */
.demo {
  opacity: 0.5;
}

.active,
.demo:hover {
  opacity: 1;
}
/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.9); /* Black w/ opacity */
}

/* Modal Content (image) */
.modal-content {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
}
/* Add Animation */
.modal-content,
#caption {
  animation-name: zoom;
  animation-duration: 0.6s;
}

@keyframes zoom {
  from {
    transform: scale(0.1);
  }
  to {
    transform: scale(1);
  }
}

/* The Close Button */
.close {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}

.close:hover,
.close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointe;
}
</style>
